<template>
    <div class="px-3 py-2"> 
        <v-form
            ref="forinv"
            lazy-validation
        >
        <b-sidebar id="pos_notes" aria-labelledby="sidebar-no-header-title" no-header backdrop :style="`direction:`+$parent.lang.ldir" right :title="$parent.lang.add_invoice" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" style="direction:rtl">
                    <div style="width:120px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                    <span>{{$parent.lang.invoice_notes}}</span>
                    </div>
                    <div @click="hide" id="nHideMe" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                    <span>{{$parent.lang.close}}</span>
                </div>
                </div>
                <v-divider></v-divider>
                <div class="px-3 py-2" style="direction:rtl">
                    <label>{{$parent.lang.discount}}</label>
                    <b-form-textarea
                        class="inborder"
                        :label="$parent.lang.invoice_notes"
                        v-model="$parent.notes" 
                    >{{ $parent.notes }}</b-form-textarea>
                </div>
            </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" @click="jhide()" hide id="addBTN" variant="success" class="ma-2 btn-sm" style="width:130px;">{{$parent.lang.add}}</b-button>
                </div>
            </template>
        </b-sidebar>
        </v-form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    methods: {
        jhide(){
            document.getElementById('nHideMe').click();
        },
        
    },
    
}
</script>