<template>
    <div class="px-3 py-2"> 
        <v-form
            ref="forinv"
            lazy-validation
        >
        <b-sidebar id="pos_addinvoice" aria-labelledby="sidebar-no-header-title" width="500px" no-header backdrop :style="`direction:`+$parent.lang.ldir" right :title="$parent.lang.add_invoice" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" style="direction:rtl">
                    <div style="width:120px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                    <span>{{$parent.lang.add_invoice}}</span>
                    </div>
                    <div @click="hide" id="iHideMe" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                    <span>{{$parent.lang.close}}</span>
                </div>
                </div>
                <v-divider></v-divider>
                <div class="px-3 py-2" style="direction:rtl">
                    <v-row>
                        <v-col cols="6">
                            <label>المبلغ المدفوع نقدا</label>
                            <b-form-input
                                :label="$parent.lang.paid_amount"
                                v-model="cash"
                                class="inborder"
                                type="number"
                                >{{ $parent.paidamount }}</b-form-input>
                        </v-col>
                      <v-col cols="6">
                        <label>المبلغ المدفوع شبكة</label>
                        <b-form-input
                            :label="$parent.lang.paid_amount"
                            v-model="span"
                            class="inborder"
                            type="number"
                        >{{ $parent.paidamount }}</b-form-input>
                      </v-col>
                      <v-col cols="6">
                        <label>اجمالي المدفوع</label>
                        <b-form-input
                            v-model="total_paid"
                            readonly
                            class="inborder"
                            type="number"
                        >{{ $parent.paidamount }}</b-form-input>
                      </v-col>
                      <v-col cols="6">
                        <label>المتبقي للعميل</label>
                        <b-form-input
                            v-model="total_return"
                            readonly
                            class="inborder"
                            type="number"
                        >{{ $parent.paidamount }}</b-form-input>
                      </v-col>
                    </v-row>
                </div>
            </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" @click="ihide(), cashSpan()" hide id="addBTN" variant="success" class="ma-2 btn-sm" style="width:130px;">{{$parent.lang.create_invoice}}</b-button>
                </div>
            </template>
        </b-sidebar>
        </v-form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            cash: 0,
            span: 0
        }
    },
    computed:{
      total_paid: function(){
        let t=0;
        // if(!isNaN(this.cash)) {
        //   this.cash = 0;
        // }
        // if(!isNaN(this.span)) {
        //   this.span = 0;
        // }
        t = parseFloat(this.cash,2) + parseFloat(this.span,2);
        return t;
      },
      total_return: function(){
        let t=0;
        t = parseFloat(this.total_paid,2) - parseFloat(this.$parent.paidamount,2);
        return t;
      },
    },
    methods: {
        cashSpan(){
          if(this.$RoundNums(this.total_paid,0) < this.$RoundNums(this.$parent.paidamount,0))
          {
            alert('المبلغ المدفوع غير مطابق للمبلغ المطلوب يرجى سداد القيمة بالكامل')
          }else{
            this.$parent.addInvoice()
          }
        },
        ihide(){
            document.getElementById('iHideMe').click();
        },
    }
}
</script>